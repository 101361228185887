import React from 'react';
import {Box} from 'rebass/styled-components';
import {graphql} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';

import markdownRenderer from '../../components/MarkdownRenderer';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import {Header} from '../../components';

import Background from './background';

export default function PrivacyPolicy({data}) {
  const {privacyPolicy} = data.contentfulProject;

  return (
    <Layout>
      <Header />
      <Section.Container id="privacy-policy" Background={Background}>
        <Box width={[1, 1, 2 / 3]} mt={[5]} ml="auto" mr="auto">
          <Fade bottom>
            <ReactMarkdown
              source={privacyPolicy.childMarkdownRemark.rawMarkdownBody}
              renderers={markdownRenderer}
            />
          </Fade>
        </Box>
      </Section.Container>
    </Layout>
  );
}
export const query = graphql`
  query($slug: String!) {
    contentfulProject(slug: {eq: $slug}) {
      privacyPolicy {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`;
